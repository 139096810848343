import './Download.css';


const Download = () => {

	return (
		<div id='download'>
			download
        </div>
	)
}

export { Download }
